import React, { useEffect, useState } from 'react';
import DomainReveal from '../DomainReveal';
import { sampleSize } from 'lodash';
import SideBox from '../SideBox';
import SubtleCta from '../SubtleCta';
import loadScript from '../../helpers/load-script';





const SubscribeSidebar = props => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const loadMauticForm = (formId, cb) => {
        if (isFormSubmitted) return;
        const script = document.createElement('script');
        script.src = 'https://bff.adstute.com/form/generate.js?id=7';
        script.async = true;
        script.onload = () => cb();
        const formElContainer = document.querySelector(`#${formId}`);
        if (formElContainer) formElContainer.append(script);
    }

    // [] should be using some DOM listening mechanism rather thatn a wait hack.
    // setTimeout(() => {
    //     const el = document.querySelector('input[name="mauticform[domainsource]"]');
    //     if (el) el.value = window.location.hostname;
    // }, 5000);

    useEffect(() => {
        // set the domain name in the form
        const formDomainNameField = document.querySelector('input[name="mauticform[domainsource]"]')
        if (formDomainNameField) formDomainNameField.value = 'Domfeed';

        if (!isFormSubmitted) loadScript('/js/mautic.js');
        if (!isFormSubmitted) loadMauticForm('subscribe-form', () => {
            // https://stackoverflow.com/questions/39221775/trigger-for-span-text-html-on-changed
            // const mauticFormEl = document.querySelector('form#mauticform_subscribe');
            // const mauticSubmitEl = document.querySelector('#mauticform_input_subscribe_submit');
            // mauticFormEl.addEventListener('submit', () => console.log('form submitted'))
            const mauticMessageEl = document.querySelector('div#mauticform_subscribe_message');
            if (mauticMessageEl) mauticMessageEl.addEventListener('DOMSubtreeModified', () => setIsFormSubmitted(true))
        });

        // we only want to useEffect when comparison of state array is false (in this case once)
    }, []);

    if (isFormSubmitted) return null;

    return (<SideBox title="Subscribe">
        <h3 style={{ marginTop: 0 }}>Stay Informed</h3>
        <p>
            We're improving Domfeed every day.
            Be the first to receive updates, articles, and exclusive offers.
        </p>
        <div id="subscribe-form"></div>
        {/* <SubtleCta to="/premium-domains" label="Subscribe Now" /> */}
    </SideBox>)
};

SubscribeSidebar.propTypes = {};

export default SubscribeSidebar;