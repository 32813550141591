import React, { useEffect, useState } from 'react';
import { MDXRenderer } from "gatsby-plugin-mdx"
// import { graphql } from 'gatsby';
// import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components';
import Layout from '../layout';
import Container from '../Container';
import OutlinedContainer from '../OutlinedContainer'
// import PremiumDomainsSidebar from '../PremiumDomainsSidebar'
// import SEO from '../seo';
// import ImageStatusBar from '../ImageStatusBar';
// import ImgBox from '../ImgBox';
import Social from '../Social';
import SubscribeSidebar from '../SubscribeSidebar';

const ArticleLayout = styled.div`
    display: flex;
    justify-content: space-between;
    .main-col {
        width: 813px;
    }
    .sidebar-col {
        flex: 1 1;
        margin-left: 15px;
    }
    @media screen  and (max-width: 1023px) {
        display: block;
        .main-col {
            width: initial;
        }
        .sidebar-col {
            width: initial;
            margin-left: initial;
        }
    }
`

const StickyBox = styled.div`
    position: sticky;
    top: 0;
    & > div {
        display: block;
    }
`

const ArticlePage = props => {
    const {
        data: {
            mdx: {
                slug,
                body,
                excerpt,
                timeToRead,
                id,
                frontmatter: {
                    title,
                    short_description,
                    cover_image: {
                        childImageSharp: {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    } = props;


    return (
        <Layout>
            {/* <SEO
                title={title}
                author="@domfeed"
                imageUrl={imageUrl}
                // description={summary || content}
                description={short_description}
            /> */}
            <Container>
                <br />
                <ArticleLayout className="article">
                    <div className="main-col">
                               <GatsbyImage
                            style={{ maxHeight: '400px' }}
                            image={gatsbyImageData}
                        />
                        {/* <ImageStatusBar
                            updateAt={modifiedOn}
                            commentQty={0}
                            likesQty={10}
                        /> */}
                        <OutlinedContainer style={{ width: '100%' }}>
                            <Social articleUrl={`https://domfeed.com/${slug}`} />
                            <MDXRenderer>
                                {body}
                            </MDXRenderer>
                            <div style={{ textAlign: 'center' }}>
                                <Social
                                    articleUrl={`https://domfeed.com/${slug}`}
                                    widgetType="large"
                                />
                            </div>
                        </OutlinedContainer>
                    </div>
                    <div className="sidebar-col">
                        <StickyBox >
                            {/* <PremiumDomainsSidebar domains={sidebarDomains} /> */}
                            <br />
                            <SubscribeSidebar />
                        </StickyBox>
                    </div>
                </ArticleLayout>

            </Container>
        </Layout >
    )
};

ArticlePage.propTypes = {};

export default ArticlePage;